import React from "react";
import "../styles/WorkContent.css";

const WorkContent = () => {
  return (
    <section className="content-section" id="work-content">
      {/* Header Section */}
      <header>
        <div className="introduction">
          <h1 className="greeting">
            Hi, I'm <span className="fullname">Brandon Lee.</span>
          </h1>
          <h2 className="current-title">
            Third-Year Computer Science Student at Oregon State University |
            Specializing in Artificial Intelligence.
          </h2>
          <h3 className="title-description">
            I'm passionate about creating solutions for everyday problems.
          </h3>
        </div>
      </header>

      {/* Main Content */}
      <main className="content-container">
        {/* Experience Section */}
        <h1 className="container-header">Experience</h1>
        <section className="experience">
          <div className="timeline">
            <div className="timeline-item">
              <div className="timeline-icon"></div>
              <div className="timeline-content">
                <div className="job-title">
                  Full-Time Student{" "}
                  <span className="present-badge">PRESENT</span>
                </div>
                <p className="company">
                  Oregon State University (Sep. 2022 - Jun. 2026)
                </p>
              </div>
            </div>
            <div className="timeline-item previous">
              <div className="timeline-icon"></div>
              <div className="timeline-content">
                <div className="job-title">Line Cook</div>
                <p className="company">
                  Dough Zone Dumpling House (Jul. 2023 - Sep. 2023)
                </p>
              </div>
            </div>
            <div className="timeline-item previous">
              <div className="timeline-icon"></div>
              <div className="timeline-content">
                <div className="job-title">Line Cook</div>
                <p className="company">Wingstop (Jul. 2021 - Dec. 2021)</p>
              </div>
            </div>
          </div>
          <div className="resume-button">
            <a
              href="https://drive.google.com/file/d/1x4mv33qo7_7s_BAMioMDxKYdG_Cr0YDm/view?usp=sharing"
              className="resume-link"
              target="_blank"
              rel="noreferrer"
            >
              View Full Resume{" "}
              <i className="fas fa-arrow-up-right-from-square"></i>
            </a>
          </div>
        </section>

        {/* Skills Section */}
        <h1 className="container-header">Skills</h1>
        <section className="skills">
          <div className="row">
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/python-color.svg" alt="Python" />
                <h2>Python</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/cplusplus-color.svg" alt="C++" />
                <h2>C++</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/javascript-color.svg" alt="JavaScript" />
                <h2>JavaScript</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/html5-color.svg" alt="HTML" />
                <h2>HTML</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/css3-color.svg" alt="CSS" />
                <h2>CSS</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/sql.svg" alt="SQL" />
                <h2>SQL</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/aws.svg" alt="AWS" />
                <h2>AWS</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/react.svg" alt="React" />
                <h2>React</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/pandas-color.svg" alt="Pandas" />
                <h2>Pandas</h2>
              </div>
            </div>
            <div className="column bs4">
              <div className="skill-item">
                <img src="assets/svg/bs4.svg" alt="Beautiful Soup" />
                <h2>Beautiful Soup</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/selenium-color.svg" alt="Selenium" />
                <h2>Selenium</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img
                  src="assets/svg/scikitlearn-color.svg"
                  alt="Scikit-learn"
                />
                <h2>Scikit-learn</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/numpy.svg" alt="NumPy" />
                <h2>NumPy</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/matplotlib.svg" alt="Matplotlib" />
                <h2>Matplotlib</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/scrapy.png" alt="Scrapy" />
                <h2>Scrapy</h2>
              </div>
            </div>
            <div className="column">
              <div className="skill-item">
                <img src="assets/svg/office365.svg" alt="Office 365" />
                <h2>Office 365</h2>
              </div>
            </div>
          </div>
        </section>

        {/* Project Section */}
        <h1 className="container-header">Projects</h1>
        <section className="projects">
          <div className="row">
            <div className="column">
              <div className="project-item">
                <a href="/cryptocurrency" id="link-crypto">
                  <div className="project-image-container">
                    <div className="image crypto"></div>
                  </div>
                </a>
                <h2 className="project-title">
                  Cryptocurrency Wallet Monitoring Bot
                </h2>
                <div className="project-info">
                  <div className="project-skills-container">
                    <h3 className="project-skills">Python</h3>
                    <h3 className="project-skills">SQL</h3>
                    <h3 className="project-skills">Scrapy</h3>
                    <h3 className="project-skills">Selenium</h3>
                    <h3 className="project-skills">Pandas</h3>
                  </div>
                  <h2 className="project-description one">
                    A bot that analyzes cryptocurrency wallets on the Solana
                    blockchain to identify consistently profitable traders,
                    uncovering key players in the crypto space.
                  </h2>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="project-item">
                <a href="/nba-proj-model" id="link-nba">
                  <div className="project-image-container">
                    <div className="image nba"></div>
                  </div>
                </a>
                <h2 className="project-title">
                  NBA Performance Prediction Model
                </h2>
                <div className="project-info">
                  <div className="project-skills-container">
                    <h3 className="project-skills">Python</h3>
                    <h3 className="project-skills">Scikit-learn</h3>
                    <h3 className="project-skills">SQL</h3>
                    <h3 className="project-skills">Pandas</h3>
                    <h3 className="project-skills">NumPy</h3>
                    <h3 className="project-skills">Beautiful Soup</h3>
                    <h3 className="project-skills">Selenium</h3>
                  </div>
                  <h2 className="project-description one">
                    A machine learning model that predicts NBA players' scoring
                    performances with 60% accuracy by clustering players into
                    archetypes using k-means.
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <div className="project-item">
                <a href="positive-ev-tracker" id="link-ev">
                  <div className="project-image-container">
                    <div className="image ev"></div>
                  </div>
                </a>
                <h2 className="project-title">Positive EV Tracker</h2>
                <div className="project-info">
                  <div className="project-skills-container">
                    <h3 className="project-skills">Python</h3>
                    <h3 className="project-skills">AWS</h3>
                    <h3 className="project-skills">SQL</h3>
                    <h3 className="project-skills">Scrapy</h3>
                    <h3 className="project-skills">Pandas</h3>
                  </div>
                  <h2 className="project-description two">
                    {" "}
                    A tool that automates the detection of profitable betting
                    opportunities by comparing real-time odds across multiple
                    sportsbooks, assisting bettors in finding positive expected
                    value (EV) bets.
                  </h2>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="project-item">
                <a href="portfolio" id="link-portfolio">
                  <div className="project-image-container">
                    <div className="image portfolio"></div>
                  </div>
                </a>
                <h2 className="project-title">Personal Portfolio - Website</h2>
                <div className="project-info">
                  <div className="project-skills-container">
                    <h3 className="project-skills">React</h3>
                    <h3 className="project-skills">HTML</h3>
                    <h3 className="project-skills">CSS</h3>
                    <h3 className="project-skills">JS</h3>
                  </div>
                  <h2 className="project-description two">
                    An interactive portfolio website developed with modern web
                    technologies to showcase a sample of my work and life
                    through a user-friendly interface.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </section>
  );
};

export default WorkContent;
