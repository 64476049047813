import React from "react";
import "../styles/Secondary.css";

const EVSection = () => (
  <section className="secondary-content-section" id="ev-content">
    <h1>Positive EV tracker</h1>
    <div className="header-image-ev"></div>
    <h2>Introduction</h2>
    <p>
      In the world of sports betting, having an edge can be the difference
      between winning and losing. Casual bettors often rely on gut feelings or
      popular opinion when placing bets, but there's a more strategic approach
      to gaining a potential advantage—finding{" "}
      <strong>positive expected value (EV)</strong> bets.
    </p>
    <h2>What is Positive EV and Why Does It Matter?</h2>
    <p>
      Before diving into my project, let's define <strong>positive EV</strong>{" "}
      for those who may not be familiar. In betting,{" "}
      <strong>expected value</strong> is a concept that estimates the average
      outcome of a bet over time. A positive EV bet means that, in the long run,
      you're expected to make a profit. Think of it as placing bets where the
      odds offered by a sportsbook are skewed in your favor.
    </p>
    <p>
      For example, imagine two sportsbooks offer different odds on the same bet.
      Book A offers <strong>-120 odds</strong>, while Book B offers{" "}
      <strong>-150 odds</strong>. Even though it's the exact same bet, you'd
      earn a higher return with Book A if your bet wins. This is a perfect
      example of Positive EV betting—identifying and taking advantage of
      discrepancies like these to maximize your returns.
    </p>
    <h2>The Problem: Finding Discrepancies between Sportsbooks</h2>
    <p>
      Sportsbooks set their odds based on analysis from teams of experts and
      statisticians, but they don't always agree on the probability of an event.
      This leads to <strong>discrepancies in the odds</strong> offered across
      sportsbooks, which offers bettors an opportunity to find positive EV bets.
    </p>
    <p>
      Manually scanning multiple sportsbooks for these discrepancies can be
      extremely time-consuming, especially with thousands of bets constantly
      changing and new ones being added by the minute. That's where automation
      comes in, and why I created this tool.
    </p>
    <h2>The Solution: Positive EV Tracker</h2>
    <p>
      The <strong>Positive EV Tracker</strong> was designed to automate the
      process of scanning odds across various sportsbooks, comparing the odds
      against each other, and keeping track of the positive EV betting
      opportunities.<h3>How It Works:</h3>
      <ol>
        <li>
          <strong>Data Gathering</strong>: The tracker pulls real-time odds from
          various sportsbooks via their APIs.
        </li>
        <li>
          <strong>Odds Comparison</strong>: Once the data is collected, the
          tracker compares odds across different sportsbooks.
        </li>
        <li>
          <strong>Calculating Positive EV</strong>: The tracker calculates a
          baseline by averaging the odds across all sportsbooks for a particular
          bet. It then compares each sportsbook's odds against this baseline,
          flagging a bet as a positive EV opportunity if it meets a threshold.
        </li>
        <li>
          <strong>Output</strong>: The final output is a list of positive EV
          bets, categorized by sportsbook, which is sent to Discord for user
          notification. It includes details such as:
          <ul>
            <li>
              <strong>Best Value</strong>: The sportsbook offering the best
              value
            </li>
            <li>
              <strong>Worst Value</strong>: The sportsbook offering the worst
              value
            </li>
            <li>
              <strong>Average Multi</strong>: The average odds across all
              sportsbooks
            </li>
            <li>
              <strong>All Odds</strong>: A list of all sportsbooks offering the
              bet, along with their odds
            </li>
          </ul>
        </li>
      </ol>
    </p>
    <h2>The Next Steps</h2>
    <p>
      Going forward, I plan to expand the tool to cover more sportsbooks.
      Currently, the tracker covers{" "}
      <strong>
        Underdog, PrizePicks, Vivid Picks, Sleeper, ParlayPlay, Draftkings
      </strong>
      , and <strong>Bet365</strong>. By adding more sportsbooks, I can reduce
      variance and create a more accurate baseline for implied odds. This will
      ultimately sharpen the tool's ability to identify profitable betting
      opportunities and improve its overall effectiveness.
    </p>
  </section>
);

export default EVSection;
